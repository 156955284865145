var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("apexchart", {
        attrs: {
          type: "bar",
          options: _vm.chartOptions,
          series: _vm.series,
          width: _vm.width,
          height: _vm.height
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }