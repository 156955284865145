<template>
    <div>
        <apexchart
            type="bar"
            :options="chartOptions"
            :series="series"
            :width="width"
            :height="height"
        ></apexchart>
    </div>
</template>
<script>
import themeMixin from "@/mixins/theme";
export default {
    name: "apex-bar-chart",
    components: {},
    mixins: [themeMixin],
    props: {
        title: {
            type: String,
            required: false,
            default: "Series 1",
        },
        height: {
            type: Number,
            default: 400,
            required: false,
        },
        width: {
            type: [Number, String],
            required: false,
            default: "100%",
        },
        categories: {
            type: Array,
            required: false,
            default: () => [],
        },
        data: {
            type: Array,
            required: true,
        },
        average: {
            type: Number,
            required: false,
            default: 0,
        },
        // Can be vuetify style colors i.e. primary darken-2 or accent lighten-1
        colorPalette: {
            type: String,
            required: false,
            default: "secondary",
        },
        // Fill color for annotation
        fillColor: {
            type: String,
            required: false,
            default: "#fff",
        },
        borderColor: {
            type: String,
            required: false,
            default: "#fff",
        },
        backgroundColor: {
            type: String,
            required: false,
            default: "alternat1 darken-2",
        },
        labelColor: {
            type: String,
            required: false,
            default: "#000",
        },
    },
    data() {
        return {
            chartOptions: {
                colors: this.themePalette(this.colorPalette),
                chart: {},
                annotations:
                    this.average === 0
                        ? {}
                        : {
                              xaxis: [
                                  {
                                      x: this.average,
                                      fillColor: this.fillColor,
                                      borderColor: this.borderColor,
                                      label: {
                                          style: {
                                              color: "#fff",
                                              background: this.themeColor(
                                                  this.backgroundColor
                                              ),
                                              fontFamily: "Roboto",
                                              fontSize: "12px",
                                              fontWeight: 700,
                                              padding: {
                                                  left: 15,
                                                  right: 15,
                                                  top: 5,
                                                  bottom: 5,
                                              },
                                          },
                                          text: "Program Average",
                                      },
                                  },
                              ],
                          },
                plotOptions: {
                    enabledOnSeries: [0],
                    bar: {
                        barHeight: "100%",
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: "bottom",
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0],
                    textAnchor: "start",
                    style: {
                        colors: [this.labelColor],
                        fontFamily: "Roboto",
                        fontWeight: 400,
                    },
                    formatter: function (val, opt) {
                        return (
                            opt.w.globals.labels[opt.dataPointIndex] +
                            ":  " +
                            val
                        );
                    },
                },
                tooltip: {
                    enabledOnSeries: [0],
                    theme: "dark",
                    x: {
                        show: false,
                    },
                    y: {
                        title: {
                            formatter: function (val, opt) {
                                return (
                                    opt.w.globals.labels[opt.dataPointIndex] +
                                    ":  "
                                );
                            },
                        },
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: this.categories,
                    labels: {
                        show: true,
                    },
                },
                legend: {
                    show: false,
                },
            },
            series: [
                {
                    name: this.title,
                    type: "bar",
                    data: this.data,
                },
            ],
        };
    },
    watch: {
        data: {
            immediate: true,
            handler(data) {
                if (data) {
                    this.series = [
                        Object.assign(
                            {},
                            {
                                data,
                                name: this.series.name,
                                type: this.series.type,
                            }
                        ),
                    ];
                }
            },
        },
        categories: {
            immediate: true,
            handler(categories) {
                let chartOptions = Object.assign({}, this.chartOptions);
                chartOptions.categories = categories;
                this.chartOptions = Object.assign({}, chartOptions);
            },
        },
        average: {
            immediate: true,
            handler(average) {
                let chartOptions = Object.assign({}, this.chartOptions);
                chartOptions.annotations.xaxis.x = average;
                this.chartOptions = Object.assign({}, chartOptions);
            },
        },
    },
};
</script>

<style scoped>
</style>